import Vue from 'vue';
import Vuex from 'vuex';
import { UserInfo } from '@/interfaces/userInfo';
import { State } from '@/interfaces/state';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    showGlobalLoader: false,
    userInfo: null
  },
  mutations: {
    showGlobalLoader(state: State, payload: boolean): void {
      state.showGlobalLoader = payload;
    },
    setUserInfo: (state: State, userInfo: UserInfo): void => {
      state.userInfo = userInfo;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow: boolean): void {
      commit('showGlobalLoader', isShow);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo): void => {
      commit('setUserInfo', userInfo);
    }
  },
  modules: {}
});
