






















import Mapbox from 'mapbox-gl-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import { Feature, featureCollection } from '@turf/helpers';
import { getColor } from '@/services/manager';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { message } from 'ant-design-vue';
import { Parcel } from '@/interfaces/parcel';
import center from '@turf/center';
import { featureEach } from '@turf/meta';
import { LngLat } from 'mapbox-gl';
import { MapComponent } from '@/interfaces/mapComponent';
import { BackgroundSource } from '@/enums/backgroundSource';
import { LinesSource } from '@/enums/linesSource';
import { getParallelismStylingRules } from '@/services/constants';
import { Map as MapboxMap, GeoJSONSource } from 'mapbox-gl';
import RulerControl from 'mapbox-gl-controls/lib/RulerControl/RulerControl';

const lineWidthStr = 'line-width';
const lineColorStr = 'line-color';

@Component({
  components: {
    Mapbox
  }
})
export default class Map extends Vue implements MapComponent {
  @Prop() linesSource: LinesSource;
  @Prop() showGapEnds: boolean;
  @Prop() showLineEnds: boolean;
  @Prop() showParallelLines: boolean;
  @Prop() backgroundSource: BackgroundSource;
  @Prop() lastUpdate: string;
  private readonly segmentColorMap = {
    gap: '#fc0303',
    line: '#03fc7b',
    default: '#000'
  };

  private readonly rasterLayerId = 'rasterLayer';
  private readonly rasterSourceId = 'rasterSource';

  private readonly linesLayerId = 'linesLayer';
  private readonly linesSourceId = 'linesSource';
  private readonly lineEndsLayerId = 'lineEndsLayer';
  private readonly lineEndsSourceId = 'lineEndsSource';
  private readonly gapEndsLayerId = 'gapEndsLayer';
  private readonly gapEndsSourceId = 'gapEndsSource';

  private readonly parallelLinesLayerId = 'parallelLinesLayer';
  private readonly parallelLinesSourceId = 'parallelLinesSource';

  private readonly tilesPolygonBorderLayerId = 'tilesPolygonBorderLayer';
  private readonly tilesPolygonLayerId = 'tilesPolygonLayer';
  private readonly tilesPolygonSourceId = 'tilesPolygonSource';

  private readonly selectedTilesPolygonLayerId = 'selectedTilesPolygonLayer';
  private readonly selectedTilesPolygonSourceId = 'selectedTilesPolygonSource';

  private readonly selectedTilesLinesLayerId = 'selectedTilesLinesLayer';
  private readonly selectedTilesLinesSourceId = 'selectedTilesLinesSource';

  private map: MapboxMap;
  private parcelId: string;
  private shapeId: string;
  private regions: AnalyticRegion[];
  private selectedRegions: string[] = [];
  private survey: DroneSurvey;
  private parcel: Parcel;

  private isRulerOn = false;

  mounted(): void {
    this.$store.dispatch('showGlobalLoader', true);
  }

  private rulerLabelFormat(number: number): string {
    if (number < 1) {
      return ''.concat((number * 1000).toFixed(2), ' m');
    }
    return ''.concat(number.toFixed(2), ' km');
  }

  onMapLoaded(map: MapboxMap): void {
    this.map = map;
    map.addControl(
      new RulerControl({
        font: ['Open Sans Bold'],
        labelFormat: this.rulerLabelFormat,
        textVariableAnchor: ['top', 'bottom', 'left'],
        textAllowOverlap: true,
        markerNodeSize: 8,
        markerNodeBorderWidth: 1
      }),
      'bottom-left'
    );
    map.on('ruler.on', () => {
      this.isRulerOn = true;
    });
    map.on('ruler.off', () => {
      this.isRulerOn = false;
    });

    this.createTilesPolygonLayer();
    this.createSelectedTileLinesLayer();
    this.createSelectedTilePolygonLayer();
    this.drawLines();

    Promise.all([this.getRegions(), this.getShape()])
      .catch((err) => {
        message.error('Something went wrong: ' + err, 5);
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
        this.onBackgroundSourceChange(this.backgroundSource);
      });
  }

  private getDroneSurvey(): Promise<DroneSurvey> {
    return API.getDroneSurvey(this.$route.query.surveyId as string);
  }

  private drawLines(): void {
    this.cleanupParcelLines();

    if (this.linesSource === LinesSource.NONE || !this.$route.query.surveyId) {
      return;
    }

    const folder = this.linesSource === LinesSource.ORIGINAL ? 'vt_original' : 'vt_curated';

    this.map.addSource(this.linesSourceId, {
      type: 'vector',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${
          this.$route.query.surveyId
        }/${folder}/{z}/{x}/{y}.mvt?v=${
          this.linesSource == LinesSource.ORIGINAL ? this.survey.LastUpdate : this.lastUpdate
        }`
      ],
      minzoom: 10,
      maxzoom: 22
    });

    this.map.addLayer({
      type: 'line',
      id: this.linesLayerId,
      source: this.linesSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        [lineWidthStr]: 1,
        [lineColorStr]: [
          'case',
          ['==', ['get', 'type'], 'line'],
          this.getSegmentColor('line'),
          ['==', ['get', 'type'], 'gap'],
          this.getSegmentColor('gap'),
          this.segmentColorMap.default
        ]
      }
    });

    if (this.linesSource === LinesSource.CURATED) {
      if (this.showLineEnds) {
        this.drawLineEnds();
      }
      if (this.showGapEnds) {
        this.drawGapEnds();
      }
    }
    this.reOrderLayers();
  }

  cleanupParcelLines(): void {
    if (this.map.getLayer(this.linesLayerId)) {
      this.map.removeLayer(this.linesLayerId);
      this.map.removeSource(this.linesSourceId);
    }
    if (this.map.getLayer(this.lineEndsLayerId)) {
      this.map.removeLayer(this.lineEndsLayerId);
      this.map.removeSource(this.lineEndsSourceId);
    }
    if (this.map.getLayer(this.gapEndsLayerId)) {
      this.map.removeLayer(this.gapEndsLayerId);
      this.map.removeSource(this.gapEndsSourceId);
    }
  }

  private drawLineEnds(): void {
    this.map.addSource(this.lineEndsSourceId, {
      type: 'geojson',
      data: `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${this.$route.query.surveyId}/line-ends.json?v=${this.lastUpdate}`
    });

    this.map.addLayer({
      type: 'circle',
      id: this.lineEndsLayerId,
      source: this.lineEndsSourceId,
      paint: {
        'circle-radius': 3,
        'circle-stroke-color': 'blue',
        'circle-stroke-width': 1,
        'circle-opacity': 0,
        'circle-stroke-opacity': 1
      }
    });
    this.reOrderLayers();
  }

  private drawGapEnds(): void {
    this.map.addSource(this.gapEndsSourceId, {
      type: 'geojson',
      data: `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${this.$route.query.surveyId}/gap-ends.json?v=${this.lastUpdate}`
    });

    this.map.addLayer({
      type: 'circle',
      id: this.gapEndsLayerId,
      source: this.gapEndsSourceId,
      paint: {
        'circle-radius': 3,
        'circle-stroke-color': '#ff00ff',
        'circle-stroke-width': 1,
        'circle-opacity': 0,
        'circle-stroke-opacity': 1
      }
    });
    this.reOrderLayers();
  }

  private getShape(): Promise<void> {
    return this.getDroneSurvey().then((survey: DroneSurvey) => {
      if (!survey) {
        return Promise.reject(`No survey ${this.$route.query.surveyId} found`);
      }
      this.survey = survey;
      this.parcelId = survey.ParcelID;
      return API.getParcel(this.parcelId).then((parcel: Parcel) => {
        this.parcel = parcel;
        this.shapeId = parcel.ShapeID;
        if (!parcel) {
          return Promise.reject(`No parcel ${this.parcelId} found`);
        }

        const coordinates = center(parcel.Shape).geometry.coordinates;
        this.map.setCenter(new LngLat(coordinates[0], coordinates[1]));
      });
    });
  }

  getRegions(): Promise<void> {
    this.selectedRegions = [];
    this.drawSelectedRegions();
    this.$emit('selectedRegionsChanged', this.selectedRegions);

    return API.getRegions(this.$route.query.surveyId as string).then((regions: AnalyticRegion[]) => {
      if (!regions) {
        return Promise.reject();
      }
      this.regions = regions;
      this.$emit('onRegionsLoaded', regions);
      this.drawRegions(regions);
    });
  }

  private createTilesPolygonLayer(): void {
    this.map.addSource(this.tilesPolygonSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'line',
      id: this.tilesPolygonBorderLayerId,
      source: this.tilesPolygonSourceId,
      paint: {
        [lineColorStr]: ['get', 'color'],
        [lineWidthStr]: 2,
        'line-offset': 1
      }
    });
    // this layer serves to handle click events because line layer doesn't trigger them
    this.map.addLayer({
      type: 'fill',
      id: this.tilesPolygonLayerId,
      source: this.tilesPolygonSourceId,
      paint: {
        'fill-opacity': 0
      }
    });
    this.map.on('click', this.tilesPolygonLayerId, (e) => {
      if (!this.isRulerOn) {
        this.onRegionClick(e.features[0].properties.id);
      }
    });
    this.map.on('mouseenter', this.tilesPolygonLayerId, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', this.tilesPolygonLayerId, () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  private createSelectedTileLinesLayer(): void {
    this.map.addSource(this.selectedTilesLinesSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'line',
      id: this.selectedTilesLinesLayerId,
      source: this.selectedTilesLinesSourceId,
      paint: {
        [lineWidthStr]: 2,
        [lineColorStr]: ['get', 'color']
      }
    });
  }

  private createSelectedTilePolygonLayer(): void {
    this.map.addSource(this.selectedTilesPolygonSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'line',
      id: this.selectedTilesPolygonLayerId,
      source: this.selectedTilesPolygonSourceId,
      paint: {
        [lineColorStr]: '#42e3f5',
        [lineWidthStr]: 3
      }
    });
  }

  private drawRegions(regions: AnalyticRegion[]) {
    const features = regions.map((region: AnalyticRegion) => {
      if (!region.polygon.properties) {
        region.polygon.properties = {};
      }
      region.polygon.properties.id = region.id;
      region.polygon.properties.color = getColor(region.state);
      return region.polygon;
    });
    const featureCollectionData = featureCollection(features);
    this.updateGeoJsonSource(this.tilesPolygonSourceId, featureCollectionData);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateGeoJsonSource(sourceId: string, geoJsonData: any): void {
    const source = this.map.getSource(sourceId) as GeoJSONSource; // set data available only for geoJSON;
    if (source) {
      source.setData(geoJsonData);
    }
  }

  private drawRasterTiles(folder: string): void {
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);

    if (this.backgroundSource === BackgroundSource.NONE) {
      return;
    }

    this.map.addSource(this.rasterSourceId, {
      type: 'raster',
      tiles: [`https://storage.googleapis.com/${folder}/{z}/{x}/{y}.png?v=${this.survey.LastUpdate}`],
      tileSize: 256,
      bounds: [this.parcel.LLLong, this.parcel.LLLat, this.parcel.URLong, this.parcel.URLat]
    });
    this.map.addLayer({
      type: 'raster',
      id: this.rasterLayerId,
      source: this.rasterSourceId
    });
    this.reOrderLayers();
  }

  @Watch('backgroundSource')
  onBackgroundSourceChange(backgroundSource: BackgroundSource): void {
    let folder = process.env.VUE_APP_DRONE_TILES_FOLDER;
    let surveyId = this.$route.query.surveyId;
    if (!surveyId) {
      return;
    }
    switch (backgroundSource) {
      case BackgroundSource.CLASSIFIER:
        folder = process.env.VUE_APP_CLASSIFIER_TILES_FOLDER;
        surveyId = `sowing-${surveyId}`;
        break;
    }
    this.drawRasterTiles(`${folder}/${surveyId}`);
  }

  @Watch('linesSource')
  @Watch('showLineEnds')
  @Watch('showGapEnds')
  onLinesSourceChange(): void {
    this.drawLines();
  }
  @Watch('showParallelLines')
  onShowParallelLinesChange(): void {
    if (this.showParallelLines) {
      this.drawParallelLines();
    } else {
      if (this.map.getLayer(this.parallelLinesLayerId)) {
        this.map.removeLayer(this.parallelLinesLayerId);
        this.map.removeSource(this.parallelLinesSourceId);
      }
    }
  }

  private drawParallelLines(): void {
    this.map.addSource(this.parallelLinesSourceId, {
      type: 'vector',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${this.$route.query.surveyId}/parallelism/{z}/{x}/{y}.mvt?v=${this.lastUpdate}`
      ],
      minzoom: 10,
      maxzoom: 22
    });
    this.map.addLayer({
      type: 'fill',
      id: this.parallelLinesLayerId,
      source: this.parallelLinesSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        'fill-color': ['case', ...getParallelismStylingRules(), '#000']
      }
    });
    this.reOrderLayers();
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  private onRegionClick(id: string): void {
    if (this.selectedRegions.includes(id)) {
      this.selectedRegions = this.selectedRegions.filter((e) => e !== id);
    } else {
      this.selectedRegions.push(id);
    }
    this.drawSelectedRegions();
    this.$emit('selectedRegionsChanged', this.selectedRegions);
  }

  private drawSelectedRegions(): void {
    const tasks = [];
    this.selectedRegions.forEach((selectedId) => {
      const region = this.regions.find((region: AnalyticRegion) => region.id === selectedId);
      if (region) {
        tasks.push(this.extendRegionWithCorrectedLines(region));
      }
    });
    this.$store.dispatch('showGlobalLoader', true);
    Promise.all(tasks)
      .then((regions: AnalyticRegion[]) => {
        const polygons = [];
        let correctedLines = [];
        regions.forEach((region: AnalyticRegion) => {
          featureEach(region.corrected, (currentFeature: Feature) => this.defineFeatureColor(currentFeature));
          polygons.push(region.polygon);
          correctedLines = correctedLines.concat(region.corrected.features);
        });
        this.updateGeoJsonSource(this.selectedTilesPolygonSourceId, featureCollection(polygons));
        this.updateGeoJsonSource(this.selectedTilesLinesSourceId, featureCollection(correctedLines));
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  private extendRegionWithCorrectedLines(region: AnalyticRegion): Promise<AnalyticRegion> {
    if (!region || !!region.corrected) {
      return Promise.resolve(region);
    }
    return API.getRegion(region.id).then((fullRegion: AnalyticRegion) => {
      region.corrected = fullRegion.corrected;
      return region;
    });
  }

  private defineFeatureColor(feature: Feature): void {
    const { properties: { type = '' } = {} } = feature;
    feature.properties.color = this.getSegmentColor(type);
  }

  private getSegmentColor(type: string): string {
    return this.segmentColorMap[type] || this.segmentColorMap.default;
  }

  reOrderLayers(): void {
    const parallelLinesLayer = this.map.getLayer(this.parallelLinesLayerId);
    const tilesPolygonBorderLayer = this.map.getLayer(this.tilesPolygonBorderLayerId);
    const tilesPolygonLayer = this.map.getLayer(this.tilesPolygonLayerId);
    const selectedTilesPolygonLayer = this.map.getLayer(this.selectedTilesPolygonLayerId);
    const selectedTilesLinesLayer = this.map.getLayer(this.selectedTilesLinesLayerId);
    const linesLayer = this.map.getLayer(this.linesLayerId);
    const lineEndsLayer = this.map.getLayer(this.lineEndsLayerId);
    const gapEndsLayer = this.map.getLayer(this.gapEndsLayerId);
    const backgroundLayer = this.map.getLayer(this.rasterLayerId);

    // layers ordering from top to bottom
    const layersOrderingList = [
      selectedTilesPolygonLayer,
      selectedTilesLinesLayer,
      tilesPolygonBorderLayer,
      tilesPolygonLayer,
      parallelLinesLayer,
      lineEndsLayer,
      gapEndsLayer,
      linesLayer,
      backgroundLayer
    ];

    const availableLayers = layersOrderingList.filter((layer) => !!layer);
    if (availableLayers.length > 1) {
      for (let i = availableLayers.length - 1; i > -1; i--) {
        this.map.moveLayer(availableLayers[i].id);
      }
    }
  }
}
