import { message } from 'ant-design-vue';
import Axios from 'axios';

import { AnalyticData } from '@/interfaces/analyticData';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { Parcel } from '@/interfaces/parcel';
import { SowingStatistics } from '@/interfaces/sowingStatistics';
import { SurveyData } from '@/interfaces/surveyData';

class API {
  static _URL = `${process.env.VUE_APP_CURATION_SERVICE}/api`;
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}/api`;
  static _ANALYTIC_LAUNCHER_URL = `${process.env.VUE_APP_ANALYTIC_LAUNCHER}`;
  static _LABELLING_SERVICE_URL = `${process.env.VUE_APP_LABELLING_SERVICE}`;

  static getRegions(surveyId: string): Promise<AnalyticRegion[]> {
    return Axios.get(`${API._URL}/analytic_regions?survey_id=${surveyId}`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static getRegion(id: string): Promise<AnalyticRegion> {
    return Axios.get(`${API._URL}/analytic_regions/${id}`, { withCredentials: true }).then((result) => result.data);
  }

  static getDroneSurvey(surveyId: string): Promise<DroneSurvey> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/survey?surveyId=${surveyId}&source=drone`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getParcel(parcelId: string): Promise<Parcel> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcel?id=${parcelId}`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static updateRegion(id: string, payload: Partial<AnalyticRegion>): Promise<void> {
    return Axios.patch(`${API._URL}/analytic_regions/${id}`, payload, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static approveAllCuratedRegions(surveyId: string): Promise<void> {
    return Axios.post(
      `${API._URL}/analytic_regions/approve-curated/${surveyId}`,
      {},
      {
        withCredentials: true
      }
    )
      .then((result) => result.data)
      .catch(() => {
        API.handleError('failed to approve curated regions');
      });
  }

  static getSurveyData(surveyId: string): Promise<SurveyData> {
    return Axios.get(`${API._URL}/survey_data/${surveyId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => {
        API.handleError('failed to load survey info');
      });
  }

  static updatePriority(surveyId: string, priority: number): Promise<void> {
    return Axios.patch(
      `${API._URL}/survey_data/${surveyId}`,
      { mcqPriority: priority },
      { withCredentials: true }
    ).then((result) => result.data);
  }

  static recalculateAnalytic(surveyId: string, applyFixes: boolean): Promise<void> {
    return Axios.post(`${API._URL}/recalculate_analytic`, { surveyId, applyFixes }, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static restartTile(surveyId: string, x: number, y: number, lines: string): Promise<void> {
    return Axios.get(`${API._ANALYTIC_LAUNCHER_URL}/restart-tile?surveyId=${surveyId}&x=${x}&y=${y}&lines=${lines}`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getJson<T>(url: string): Promise<T> {
    return Axios.get(url, { withCredentials: true }).then((result) => result.data);
  }

  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize?role=${role}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static async getAnalyticStatus(surveyId: string, analytic: string): Promise<AnalyticData> {
    try {
      const result = await Axios.get(
        `${API._PRODUCT_MANAGER_URL}/analytic-status?id=${surveyId}&analytic=${analytic}&type=drone`,
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError('Could not retrieve Analytic Status');
    }
  }

  static async getStatistics(surveyId: string): Promise<SowingStatistics> {
    try {
      const result = await Axios.get(
        `https://storage.googleapis.com/${process.env.VUE_APP_SOWING_FOLDER}/${surveyId}/stats.json`
      );
      return result.data;
    } catch (error) {
      API.handleError('Could not load stats.json');
    }
  }

  static async publishAnalytic(surveyId: string): Promise<void> {
    try {
      const result = await Axios.post(
        `${API._URL}/survey_data/publish/${surveyId}`,
        {
          analyticType: 'sowing'
        },
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError();
    }
  }

  static async unPublishAnalytic(surveyId: string): Promise<void> {
    try {
      const result = await Axios.post(
        `${API._URL}/survey_data/unpublish/${surveyId}`,
        { analyticType: 'sowing' },
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError();
    }
  }

  static async getTags(): Promise<string[]> {
    try {
      const result = await Axios.get(`${API._LABELLING_SERVICE_URL}/tags`, {
        withCredentials: true
      });
      return Object.keys(result.data);
    } catch (error) {
      API.handleError();
    }
  }

  static async getTileTags(id: string): Promise<string[]> {
    try {
      const result = await Axios.get(`${API._LABELLING_SERVICE_URL}/gettiletags?id=${id}`, {
        withCredentials: true
      });
      return Object.keys(result.data);
    } catch (error) {
      API.handleError();
    }
  }

  static async setLabellingTileTags(id: string, tags: string[]): Promise<boolean> {
    try {
      const result = await Axios.post(`${API._LABELLING_SERVICE_URL}/settiletags?id=${id}`, tags, {
        withCredentials: true
      });
      return result.data.success;
    } catch (error) {
      API.handleError();
    }
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static handleError(err = 'something went wrong'): void {
    message.error(err);
  }
}

export default API;
