var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
    container: 'map',
    style: {
      version: 8,
      sources: {},
      zoom: 18,
      layers: [],
      id: 'gamaya',
      glyphs: 'https://fonts.openmaptiles.org/{fontstack}/{range}.pbf'
    },
    pitchWithRotate: false,
    preserveDrawingBuffer: true
  },"scale-control":{ show: true },"nav-control":{ show: true, position: 'bottom-left' }},on:{"map-load":_vm.onMapLoaded}})}
var staticRenderFns = []

export { render, staticRenderFns }