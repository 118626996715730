















































































































































































import { Component, Vue } from 'vue-property-decorator';
import Map from '@/components/Map.vue';
import { AnalyticRegion } from '@/interfaces/analyticRegion';
import { AnalyticRegionLegend } from '@/interfaces/analyticRegionLegend';
import { AnalyticRegionStateType } from '@/enums/analyticRegionStateType';
import { getColor } from '@/services/manager';
import { StateType } from '@/enums/stateType';
import API from '@/services/api';
import { MapComponent } from '@/interfaces/mapComponent';
import { message } from 'ant-design-vue';
import { AnalyticData } from '@/interfaces/analyticData';
import { AuthorizeResponse } from '@/interfaces/auth';
import { BackgroundSource } from '@/enums/backgroundSource';
import { LinesSource } from '@/enums/linesSource';
import { SowingStatistics } from '@/interfaces/sowingStatistics';
import { TileStatusType } from '@/enums/tileStatus';
import { AnalyticValues } from '@/interfaces/analyticValues';
import { ProcessingState } from '@/interfaces/surveyData';
import { FeatureCollection, Polygon } from '@turf/helpers';
import Intersect from '@turf/intersect';
import * as Sentry from '@sentry/vue';
@Component({
  components: {
    Map
  }
})
export default class Operator extends Vue {
  $refs: {
    map: MapComponent;
  };
  parcelPriority = null;
  lastUpdate = null;
  backgroundSourceEnum = BackgroundSource;
  backgroundSource = BackgroundSource.DRONE;
  linesSourceEnum = LinesSource;
  tileStatusType = TileStatusType;
  linesSource = LinesSource.CURATED;
  showLineEnds = true;
  showGapEnds = true;
  showParallelLines = false;
  applyDataFixes = false;
  legend: AnalyticRegionLegend[] = [];
  selectedRegionIds = [];
  tags: string[];
  tileTagStatus = null;
  firstSelectedRegion;
  selectedPlantingLines = 'Single';
  selectedState: StateType = StateType.toCurate;
  analyticValues: AnalyticValues = {
    GapLength: 0,
    TotalLength: 0,
    UnusedArea: 0,
    TramplingLength: 0,
    AnalyticAccuracy: 0
  };
  states = [
    { state: StateType.toCurate, name: 'To curate' },
    { state: StateType.reject, name: 'Reject' },
    { state: StateType.approve, name: 'Approve' }
  ];
  private regions: AnalyticRegion[] = [];
  isPublished = null;
  totalCurationTime = 0;
  statistics: SowingStatistics = null;
  isRecalculated = false;
  processingState: ProcessingState | null = null;

  private refreshSessionInterval = 600000; // millisecond, 10 min

  get surveyId(): string {
    return this.$route.query.surveyId as string;
  }

  get isStateInProgress(): boolean {
    return this.processingState === ProcessingState.InProgress;
  }

  async mounted(): Promise<void> {
    try {
      const response = await API.authorize('curator');
      const userInfo = response.UserInfo;
      this.$store.dispatch('setUserInfo', userInfo);
      Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
      if (this.$route.query.surveyId) {
        this.isPublished = false;
        API.getAnalyticStatus(this.$route.query.surveyId as string, 'sowing').then((data: AnalyticData) => {
          if (data) {
            if (!data.Published) {
              API.getAnalyticStatus(this.$route.query.surveyId as string, 'phl').then((data: AnalyticData) => {
                if (data && data.Published) {
                  this.isPublished = !!data.Published;
                  this.lastUpdate = data.LastUpdate || new Date().getTime();
                } else {
                  this.lastUpdate = `${new Date().getTime()}`;
                }
              });
            } else {
              this.isPublished = !!data.Published;
              this.lastUpdate = data.LastUpdate || new Date().getTime();
            }
          } else {
            this.lastUpdate = `${new Date().getTime()}`;
          }
        });
        API.getTags().then((tags) => {
          this.tags = tags;
          API.getStatistics(this.$route.query.surveyId as string).then((statistics: SowingStatistics) => {
            this.statistics = statistics;
            this.analyticValues.AnalyticAccuracy = Math.round(this.statistics.analyticAccuracy * 100) / 100;
          });
          this.loadSurveyData();
        });
      }
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }

    setInterval(() => {
      /* eslint-disable-next-line no-console */
      console.info('refreshSession...');
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  getAccuracyLength(original: number, curated: number): string {
    if (typeof original === 'number' && !!curated) {
      return `${Math.round((original / curated) * 100 * 100) / 100}%`;
    }
    return '-';
  }

  handlePriorityChange(): void {
    this.$store.dispatch('showGlobalLoader', true);
    API.updatePriority(this.$route.query.surveyId as string, this.parcelPriority)
      .then(() => {
        message.success('Priority successfully changed!', 5);
      })
      .catch(() => {
        message.error('Something went wrong!', 5);
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }
  async updateTileStatus(): Promise<void> {
    this.firstSelectedRegion.tags = this.tileTagStatus.filter((x) => x.checked).map((x) => x.tag);
    await API.updateRegion(this.firstSelectedRegion.id, {
      tileStatus: this.firstSelectedRegion.tileStatus,
      tags: this.firstSelectedRegion.tags
    });
  }
  async setForLabelling() {
    const survey = await API.getDroneSurvey(this.$route.query.surveyId as string);
    const parcel = await API.getParcel(survey.ParcelID);
    const tags = this.tileTagStatus.filter((x) => x.checked).map((x) => x.tag);
    const reqs = [];
    for (let x = 0; x < 2; x++) {
      for (let y = 0; y < 2; y++) {
        if (this.tileOverlaps(this.firstSelectedRegion.x * 2 + x, this.firstSelectedRegion.y * 2 + y, parcel.Shape))
          reqs.push(
            API.setLabellingTileTags(
              this.surveyId + '_' + (this.firstSelectedRegion.x * 2 + x) + '_' + (this.firstSelectedRegion.y * 2 + y),
              tags
            )
          );
      }
    }
    await Promise.all(reqs);
  }
  tileOverlaps(x: number, y: number, shape: FeatureCollection): boolean {
    const tl = this.getLatLngFromTile(x, y, 20);
    const br = this.getLatLngFromTile(x + 1, y + 1, 20);
    const coords = [
      [
        [tl.lng, tl.lat],
        [tl.lng, br.lat],
        [br.lng, br.lat],
        [br.lng, tl.lat],
        [tl.lng, tl.lat]
      ]
    ];
    const featPoly: Polygon = { type: 'Polygon', coordinates: coords };
    const intersection = Intersect(featPoly, shape.features[0] as any);
    return !!intersection;
  }
  getLatLngFromTile(x: number, y: number, z: number): { lat: number; lng: number; zoom: number } {
    const lng = (x / Math.pow(2, z)) * 360 - 180;

    const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
    const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
    return { lat: lat, lng: lng, zoom: z };
  }

  onRegionsLoaded(regions: AnalyticRegion[]): void {
    this.regions = regions;
    const legend: AnalyticRegionLegend[] = [];
    Object.values(AnalyticRegionStateType).forEach((state: string) => {
      const legendItem: AnalyticRegionLegend = {
        state,
        color: getColor(state as AnalyticRegionStateType),
        count: regions.filter((region: AnalyticRegion) => region.state === state).length
      };
      legend.push(legendItem);
    });
    this.legend = legend;
  }

  async onSelectedRegionsChanged(ids: string[]): Promise<void> {
    this.selectedRegionIds = ids;
    this.firstSelectedRegion = this.regions.find((region: AnalyticRegion) => region.id === this.selectedRegionIds[0]);
    if (this.firstSelectedRegion) {
      this.tileTagStatus = this.tags.map((tag) => {
        return { tag: tag, checked: this.firstSelectedRegion.tags && this.firstSelectedRegion.tags.indexOf(tag) >= 0 };
      });
    }
  }

  combineRegions(): void {
    this.$store.dispatch('showGlobalLoader', true);
    API.recalculateAnalytic(this.$route.query.surveyId as string, this.applyDataFixes)
      .then(() => {
        message.success('Command to recombine successfully sent!', 5);
        setTimeout(() => this.trackProcessingState(this.surveyId), 2000);
        return this.loadSurveyData();
      })
      .catch(() => {
        message.error('Something went wrong!', 5);
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  applyState(): void {
    this.$store.dispatch('showGlobalLoader', true);
    let state = AnalyticRegionStateType.CurationRequired;
    if (this.selectedState === StateType.approve) {
      state = AnalyticRegionStateType.Approved;
    }
    const tasks = this.selectedRegionIds.map((id: string) => {
      return API.updateRegion(id, { state });
    });
    Promise.all(tasks)
      .then(() => {
        message.success('State successfully applied!', 5);
        return this.$refs.map.getRegions();
      })
      .catch(() => {
        message.error('Something went wrong!', 5);
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  async approveAllCurated(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    try {
      await API.approveAllCuratedRegions(this.surveyId);
      await this.$refs.map.getRegions();
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }

  curateRegion(): void {
    const region = this.regions.find((region: AnalyticRegion) => region.id === this.selectedRegionIds[0]);
    if (region) {
      window.open(`${process.env.VUE_APP_CURATOR_URL}?id=${region.id}`, '_blank');
    }
  }

  downloadRegion(original = false): void {
    const region = this.regions.find((region: AnalyticRegion) => region.id === this.selectedRegionIds[0]);
    if (region) {
      const url = `https://rfp-tool.gamaya.com/download?id=${region.id}${original ? '&mode=o' : ''}`;
      window.open(url, '_blank');
    }
  }

  downloadTile(): void {
    const region = this.firstSelectedRegion;
    if (region) {
      const url = `${process.env.VUE_APP_ANALYTIC_LAUNCHER}/download-tile?surveyId=${region.surveyId}&x=${region.x}&y=${region.y}`;
      window.open(url + '&type=png', '_blank');
      window.open(url + '&type=geojson', '_blank');
    }
  }

  async restartTile(): Promise<void> {
    if (!confirm('Are you sure? Reprocessing might take up to 20min...')) return;

    const region = this.firstSelectedRegion;
    if (region) {
      await API.restartTile(region.surveyId, region.x, region.y, this.selectedPlantingLines == 'Double' ? '2' : '1');
    }
  }

  async publish(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    await API.publishAnalytic(this.$route.query.surveyId as string)
      .then(() => {
        this.isPublished = true;
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  async unPublish(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    await API.unPublishAnalytic(this.$route.query.surveyId as string)
      .then(() => {
        this.isPublished = false;
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  async loadSurveyData(): Promise<void> {
    const surveyData = await API.getSurveyData(this.$route.query.surveyId as string);
    if (surveyData) {
      this.parcelPriority = surveyData.mcqPriority;
      this.totalCurationTime = surveyData.totalCurationTime;
      this.isRecalculated = surveyData.isRecalculated;
      this.processingState = surveyData.processingState;
    }

    const totalLength = await API.getAnalyticStatus(this.$route.query.surveyId as string, 'sowingTotalLength');
    const gapLength = await API.getAnalyticStatus(this.$route.query.surveyId as string, 'sowingGapLength');
    const unusedArea = await API.getAnalyticStatus(this.$route.query.surveyId as string, 'unusedArea');
    const tramplingLength = await API.getAnalyticStatus(this.$route.query.surveyId as string, 'tramplingLength');

    this.analyticValues.GapLength = gapLength.Value;
    this.analyticValues.TotalLength = totalLength.Value;
    this.analyticValues.UnusedArea = unusedArea.Value;
    this.analyticValues.TramplingLength = tramplingLength.Value;
    this.analyticValues.AnalyticAccuracy = Math.round(this.statistics.analyticAccuracy * 100) / 100;
  }

  async trackProcessingState(surveyId: string): Promise<void> {
    await this.loadSurveyData();
    if (this.processingState === ProcessingState.Initial || this.processingState === ProcessingState.InProgress) {
      // recursive run
      setTimeout(() => this.trackProcessingState(surveyId), 5000);
    }
  }
}
