export enum ProcessingState {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Failed = 'Failed'
}

export interface SurveyData {
  surveyId: string;
  mcqPriority: number;
  totalCurationTime: number;
  isRecalculated?: boolean;
  processingState?: ProcessingState;
}
