import { render, staticRenderFns } from "./Operator.vue?vue&type=template&id=1027facd&scoped=true&"
import script from "./Operator.vue?vue&type=script&lang=ts&"
export * from "./Operator.vue?vue&type=script&lang=ts&"
import style0 from "./Operator.vue?vue&type=style&index=0&id=1027facd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1027facd",
  null
  
)

export default component.exports