import Vue from 'vue';
import VueRouter from 'vue-router';
import Operator from '../views/Operator.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Operator',
    component: Operator
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
